<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="500px">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row>
        <el-col class="padding-right-xs">
          <el-card class="margin-bottom-xs">
            <el-row>
              <el-form-item label="用户登录名" prop="userName">
                <el-input v-model="dataInfo.userName" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="用户类别" prop="userType">
                <el-input v-model="dataInfo.userType" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="用户全称" prop="fullName">
                <el-input v-model="dataInfo.fullName" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="分组权限" prop="eGroupCode">
                <el-input v-model="dataInfo.eGroupCode" autocomplete="off" placeholder="E120为东华总部"></el-input>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="save" type="primary">保存</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      editType: '',
      activeName: 'base',
      dataRules: {}
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    async save() {
      await this.$lgh.get('api/auth/user/set/groupcode', { userId: this.dataInfo.userId, groupCode: this.dataInfo.eGroupCode })
      this.visible = false
      this.onsuccess && this.onsuccess(this.dataInfo)
    }
  }
}
</script>

<style></style>
