<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="员工类型">
          <l-select v-model="condition.mType" :data="$constant.employeeMTypeList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="在职情况">
          <l-select v-model="condition.isQuit" :data="$constant.employeeIsQuitList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="condition.keyword" size="small" placeholder="姓名|电话|职位"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list">
        <template slot="name" slot-scope="scope">
          <div class="flex align-center">
            <!-- <el-avatar size="small" :src="scope.row.avatar"></el-avatar> -->
            <span class="padding-left-xs" :class="scope.row.employeeType == 10 ? 'text-purple' : ''">{{ scope.row.name }}</span>
          </div>
        </template>
        <template slot="mType" slot-scope="scope">
          <span v-if="scope.row.mType == 'formal'">正式员工</span>
          <span v-else-if="scope.row.mType == 'external'" class="text-cyan">外雇员工</span>
        </template>
        <template slot="title" slot-scope="scope">
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isBoss" class="margin-right-mini">公司老板</el-tag>
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isSenior" class="margin-right-mini">企业高管</el-tag>
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isLeader" class="margin-right-mini">部门领导</el-tag>
          <el-tag effect="dark" size="mini" v-if="!scope.row.isBoss && !scope.row.isSenior && !scope.row.isLeader" class="margin-right-mini">普通员工</el-tag>
          <el-tag type="warning" effect="dark" size="mini" v-if="scope.row.isAdmin" class="margin-right-mini">管理员</el-tag>
          <el-tag type="primary" effect="dark" size="mini" v-if="scope.row.title">{{ scope.row.title }}</el-tag>
        </template>
        <template slot="mType22" slot-scope="scope">
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.mType == 'formal'">正式员工</el-tag>
          <el-tag type="success" effect="dark" size="mini" v-else-if="scope.row.mType == 'external'">外雇员工</el-tag>
        </template>
        <template slot="isQuit" slot-scope="scope">
          <span v-if="!scope.row.isQuit" class="text-green">在职</span>
          <span v-if="scope.row.isQuit" class="text-red">离职</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import userEdit from './employeeUserEdit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'Employee',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '企业', prop: 'enterpriseName' },
          { label: '姓名', slot: 'name' },
          { label: '手机号', prop: 'mobile' },
          { label: '性别', prop: 'gender' },
          { label: '分组', prop: 'eGroupCode' },
          { label: '部门', prop: 'departName' },
          { label: '员工类型', slot: 'mType' },
          { label: '员工标签', slot: 'title' },
          { label: '在职状态', slot: 'isQuit' }
        ],
        operation: {
          width: '100',
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '信息', click: this.editUserInfo }]
        }
      },
      condition: {
        keyword: '',
        enterpriseCode: '',
        departId: '',
        mType: '',
        isQuit: '',
        isLeader: '',
        isSenior: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/employee/list', this.condition)
    },
    async editUserInfo (item) {
      // console.log(item)
      if (!item.userInfo) {
        item.userInfo = await this.$lgh.get('api/auth/user/info/id', { userId: item.userId })
      }
      // console.log(item.userInfo)
      await this.$lgh.openDialog({
        ins: userEdit,
        title: '用户信息',
        data: {
          dataInfo: item.userInfo
        }
      })
    }
  }
}
</script>
